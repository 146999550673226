// leave off @2x/@3x
const images = {
  ready: require('../Images/background-carousel.png'),
  background: require('../Images/background-carousel.jpg'),
  consultingBackground: require('../Images/background-consulting.jpg'),
  backgroundChallengeConcluded: require('../Images/backgroundChallengeConcluded.png'),
  limaslab: require('../Images/background-carousel.png'),
  backButton: require('../Images/Icons/back-button.svg'),
  plusGreen: require('../Images/Icons/plus-green.svg'),
  plusPurple: require('../Images/Icons/plus-purple.png'),
  exclamation: require('../Images/Icons/exclamation.svg'),
  secondLogo: require('../Images/mostylogo2.png'),
  addClientBadge: require('../Images/Icons/add-client-badge.png'),
  iconZoom: require('../Images/Icons/icon-zoom.svg'),
  whatsapp: require('../Images/Icons/whatsapp.svg'),
  thumbnail: require('../Images/Icons/folder.svg'),
  package: require('../Images/Icons/package.png'),
  introMedal: require('../Images/intro-medal.png'),
  avatar: require('../Images/Icons/avatar.svg'),
  backgroundFirstLogin: require('../Images/background-first-login.jpg'),
  medal: require('../Images/Icons/medal.svg'),
  people: require('../Images/Icons/people.svg'),
  plusWhite: require('../Images/Icons/plus-white.svg'),
  star: require('../Images/Icons/star.svg'),
  gift: require('../Images/Icons/gift.svg'),
  play: require('../Images/Icons/play.svg'),
  combinou: require('../Images/Icons/combinou.svg'),
  house: require('../Images/Icons/house.svg'),
  check: require('../Images/Icons/check.svg'),
  checkGreen: require('../Images/Icons/check-green.svg'),
  commitment: require('../Images/Icons/commitment-menu.svg'),
  edit: require('../Images/Icons/edit.svg'),
  editPrimary: require('../Images/Icons/edit-primary.svg'),
  googleMaps: require('../Images/Map/google-maps.png'),
  waze: require('../Images/Map/waze.png'),
  exampleTemplate: require('../Images/exampleTemplate.png'),
  noCheck: require('../Images/Icons/no-check.svg'),
  menu: require('../Images/Icons/menu.svg'),
  trash: require('../Images/Icons/trash.svg'),
  order: require('../Images/Icons/order.png'),
  cancel: require('../Images/Icons/cancel.png'),
  birthdayBackground: require('../Images/carousel-aniversario.png'),
  commitmentsBackground: require('../Images/carousel-compromissos.png'),
  financialBackground: require('../Images/carousel-financeiro.png'),
  moodboardImage: require('../Images/moodboardImage.png'),
  moodboardImageText: require('../Images/moodboardImageText.png'),
  moodboardText: require('../Images/moodboardText.png'),
  view: require('../Images/view.png'),
  right: require('../Images/right.png'),
  warning: require('../Images/warning.png'),
  financialIcon: require('../Images/Icons/financial.svg'),
  filterIcon: require('../Images/Icons/filter.svg'),
  doubleDownArrow: require('../Images/Icons/double-down-arrow.svg'),
  arrowUp: require('../Images/Icons/arrow-up.svg'),
  arrowDown: require('../Images/Icons/arrow-down.svg'),
  billingIcon: require('../Images/Icons/billing.svg'),
  repeat: require('../Images/Icons/repeat.svg'),
  birthdayGift: require('../Images/Icons/birthday-gift.svg'),
  calendar: require('../Images/Icons/calendar.svg'),
  routeBoard: require('../Images/Icons/route-board.svg'),
  routeBoardPurple: require('../Images/Icons/route-board-purple.svg'),
  clientMoodboardBg: require('../Images/interface.png'),
  materialDeveloperBoardWhite: require('../Images/Icons/icon_material_developer_board_white.svg'),
  planList: require('../Images/Icons/plan-list.svg')
}

export const carouselBackground = require('../Images/background-carousel.png')

export default images
