import { all, put, call, select } from 'redux-saga/effects'

import UserTypes from '../Redux/UserRedux'
import ClientsTypes from '../Redux/ClientsRedux'
import SettingsTypes from '../Redux/SettingsRedux'
import PaymentTypes from '../Redux/PaymentRedux'

import API from '../Services/Api'
import { referralCodeToId } from '../Services/Helpers'

export function * logout () {
  try {
    yield all([
      yield put(UserTypes.setToken('')),
      yield put(UserTypes.setSubscription(null)),
      yield put(PaymentTypes.setCards([])),
      yield put(PaymentTypes.setDefaultPaymentMethod(null, null)),
      yield put(SettingsTypes.setSignUpFirstStepData(null)),
      // Closes opened popups to prevent screen lock in case of a token expiration (mainly for terms update handler popup)
      yield put(SettingsTypes.closePopup()),
      yield put(UserTypes.setConsultantProfile(null)),
      yield put(UserTypes.setPlan(undefined)),
      yield put(UserTypes.setUser(null)),
      yield put(UserTypes.setLevel({})),
      yield put(UserTypes.setAchievementsList([])),
      yield put(ClientsTypes.setClientsList([]))
    ])
  } catch (error) {
    console.log(error)
  }
}

export function * loginByFacebookRoutine ({ accessToken }) {
  try {
    yield put(SettingsTypes.setLoading(true))
    const response = yield API.loginFb({ token: accessToken, auth: 50 })
    if (response) {
      yield call(onLoginRoutine, response)
    } else {
      console.error('Error on facebook login response', response)
    }
  } catch (error) {
    console.log(error)
  } finally {
    yield put(SettingsTypes.setLoading(false))
  }
}

export function * loginByEmailRoutine ({ credentials }) {
  try {
    yield call(loginByEmailProcedure, credentials)
  } catch (error) {
    console.error(error)
  }
}

export function * signUpRoutine ({ credentials }) {
  try {
    // If creating a consultant account, check if has been referred
    if (credentials.auth === 50) {
      const referralId = yield call(extractReferralId)
      if (referralId) {
        credentials.referralId = referralId
      }
    }

    yield put(SettingsTypes.setLoading(true))
    yield call(API.createUser, credentials)
    yield all([
      yield put(
        SettingsTypes.showPositiveToast('Cadastro feito com sucesso.', 2000)
      ),
      yield call(loginByEmailProcedure, credentials),
      yield put(UserTypes.setReferralCode(null)) // Ensure referral code is deleted after signup
    ])
  } catch (error) {
    console.error(error)
  } finally {
    yield put(SettingsTypes.setLoading(false))
  }
}

export function * loginByEmailProcedure (credentials) {
  try {
    yield put(SettingsTypes.setLoading(true))
    const response = yield API.localLogin({
      email: credentials.email,
      password: credentials.password
    })
    yield all([yield call(onLoginRoutine, response)])
  } catch (error) {
    console.error(error)
  } finally {
    yield put(SettingsTypes.setLoading(false))
  }
}

const onLoginRoutine = function * (response) {
  // Checks if user is a consultant
  const auth = response.data && response.data.auth
  if (auth !== 50) {
    yield put(
      SettingsTypes.showNegativeToast(
        'Você não é uma consultora válida! Contacte o administrador.',
        2000
      )
    )
    console.warn('An user attempted to login Consultant App using auth=', auth)
    return
  }

  // Sets token
  yield put(UserTypes.setToken(response.headers.authorization))

  try {
    // Fetches basic data
    const [
      user,
      consultantProfile,
      clientList,
      cards,
      achievementsList,
      userLevel
    ] = yield all([
      API.getMe(),
      API.getConsultantProfile(),
      API.getClientsList(),
      API.listCards(),
      API.getAchievementsList(),
      API.getUserLevel(),
      call(refreshSubscription)
    ])

    if (!user.data.phone) {
      yield put(SettingsTypes.setShowPhoneRegisterModal(true)) // To wait back-end
    }
    yield all([
      yield put(ClientsTypes.setClientsList(clientList.data)),
      yield put(SettingsTypes.setSignUpFirstStepData(null)),
      yield put(UserTypes.setConsultantProfile(consultantProfile.data)),
      yield put(UserTypes.setAchievementsList(achievementsList.data.results)),
      yield put(UserTypes.setLevel(userLevel.data)),
      yield put(UserTypes.setUser(user.data)),
      yield put(PaymentTypes.setCards(cards.data))
    ])
  } catch (error) {
    console.error(error)
  }
}

export const refreshSubscription = function * () {
  const { data: subscription } = yield API.getSubscription()

  const currentPlanPeriod = subscription.planIdentifier
  if (!currentPlanPeriod) {
    yield put(UserTypes.setPlan({}))
  } else {
    const { data: planPeriod } = yield API.getPlanPeriod(currentPlanPeriod)
    yield put(UserTypes.setPlan(planPeriod.plan))
  }

  yield put(UserTypes.setSubscription(subscription))
}

const extractReferralId = function * () {
  const referralCode = yield select(state => state.user.referralCode)
  if (!referralCode) return null
  // Convert referral code to userId
  const referralId = yield referralCodeToId(referralCode)
  return referralId
}
