import { takeLatest, all, fork } from 'redux-saga/effects'

/* ------------- Types ------------- */

import { SettingsTypes } from '../Redux/SettingsRedux'
import { UserTypes } from '../Redux/UserRedux'
import { ClientsTypes } from '../Redux/ClientsRedux'
import { PaymentTypes } from '../Redux/PaymentRedux'

/* ------------- Sagas ------------- */

import {
  showPositiveToast,
  showNegativeToast,
  showNeutralToast
} from './SettingsSagas'
import { addCard, changeDefaultPaymentMethodIfNecessary } from './PaymentSagas'
import {
  logout,
  loginByEmailRoutine,
  loginByFacebookRoutine,
  signUpRoutine,
  refreshSubscription
} from './UserSagas'
import {
  getSelectedClientData,
  monitorMoodboardUploadingQueue,
  setClientsToShowUnregisteredAlert
} from './ClientsSagas'
import { checksAndNotifiesCompletedAchievement } from './GamificationSagas'

/* ------------- Connect Types To Sagas ------------- */

export default function * root () {
  yield all([
    takeLatest(PaymentTypes.ADD_CARD, addCard),
    takeLatest(PaymentTypes.SET_CARDS, changeDefaultPaymentMethodIfNecessary),
    takeLatest(SettingsTypes.SHOW_POSITIVE_TOAST, showPositiveToast),
    takeLatest(SettingsTypes.SHOW_NEGATIVE_TOAST, showNegativeToast),
    takeLatest(UserTypes.LOGIN_BY_FACEBOOK_ROUTINE, loginByFacebookRoutine),
    takeLatest(SettingsTypes.SHOW_NEUTRAL_TOAST, showNeutralToast),
    takeLatest(UserTypes.SIGN_UP_ROUTINE, signUpRoutine),
    takeLatest(UserTypes.LOGIN_BY_EMAIL_ROUTINE, loginByEmailRoutine),
    takeLatest(UserTypes.LOGOUT, logout),
    takeLatest(UserTypes.REFRESH_SUBSCRIPTION, refreshSubscription),
    takeLatest(
      ClientsTypes.SET_CLIENTS_LIST,
      setClientsToShowUnregisteredAlert
    ),
    // takeLatest(ClientsTypes.SEE_CLIENT, checksAndNotifiesCompletedAchievement),
    // takeLatest(ClientsTypes.PUT_CLIENT, checksAndNotifiesCompletedAchievement),
    // takeLatest(
    //   ClientsTypes.PUT_CLIENT_ATTRIBUTE,
    //   checksAndNotifiesCompletedAchievement
    // ),
    // takeLatest(
    //   SettingsTypes.PUSH_TO_MOODBOARD_UPLOADING_QUEUE,
    //   checksAndNotifiesCompletedAchievement
    // ),
    // takeLatest(
    //   UserTypes.RECOMMEND_THE_APP,
    //   checksAndNotifiesCompletedAchievement
    // ),
    takeLatest(ClientsTypes.GET_SELECTED_CLIENT_DATA, getSelectedClientData),
    fork(monitorMoodboardUploadingQueue)
  ])
}
