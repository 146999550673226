import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

class IntroHandler extends Component {
  componentDidMount () {
    if (this.props.token && this.props.firstLogin && this.props.plan) {
      this.props.history.replace('/introducao')
    }
  }

  componentDidUpdate (prevProps) {
    const isLoaded = prevProps.loading && !this.props.loading

    if (
      this.props.token &&
      this.props.firstLogin &&
      this.props.plan &&
      isLoaded
    ) {
      this.props.history.replace('/introducao')
    }
  }
  render () {
    return this.props.children || null
  }
}

const mapSateToProps = state => ({
  token: state.user.token,
  firstLogin: state.user.firstLogin,
  plan: state.user.plan,
  loading: state.settings.loading
})

export default connect(mapSateToProps, null)(withRouter(IntroHandler))
