import apisauce from 'apisauce'
import moment from 'moment'
import cepApi from 'cep-promise'

import ApiError, { showErrorMessage } from './ApiError'
import AppConfig from '../../Config/AppConfig'

/*
Defines a simple wrapper for requests
Basically, when response is NOT `ok`, it will throw the error
This is motivated to centralize error handling on catch (Without needing to check for `ok=true`)
*/
const responseWrapper = config => async response => {
  if (response.ok) {
    return response
  }

  // Else, an error happened!
  showErrorMessage(response, config)

  // If response.status = 401, the token is not valid!
  // User should be logged out, for instance
  if (response.status === 401) {
    if (typeof config.onBadToken === 'function') {
      config.onBadToken()
    }
  }

  throw new ApiError(response)
}

/*
Simple wrapper that receives the `request` object, and binds
the user token on Authorization header
- Must receive `tokenSelector` on configs, which is the handler
  responsible for grabbing the token (Probably from redux)
*/
const bindToken = config => async request => {
  const currentToken = config.tokenSelector()
  if (currentToken) {
    request.headers['Authorization'] = currentToken
  }
}

/*
Handler to actualy initialize API service.
Responsible for receiving config input, setting wrappers, and return a
simple requests interface
*/
export const create = (input = {}) => {
  // Config object
  const config = {
    baseURL: input.baseURL || AppConfig.apiUri,
    tokenSelector: input.tokenSelector || (() => ''),
    onBadToken: input.onBadToken,
    showNegativeToast: input.showNegativeToast
  }

  // Basic api init
  const api = apisauce.create({
    // base URL is read from the "constructor"
    baseURL: config.baseURL,
    // 60 second timeout...
    timeout: 60000
  })

  // local api init for test
  // const localApi = apisauce.create({
  //   // base URL is read from the "constructor"
  //   baseURL: 'http://localhost:4000/api/v2',
  //   // 60 second timeout...
  //   timeout: 60000
  // })

  // Moodboard api init
  // [TODO] Remove this API when proxy supports moodboard again
  const moodboardApi = apisauce.create({
    // base URL is read from the "constructor"
    baseURL: AppConfig.moodboardApiUri,
    // 60 second timeout...
    timeout: 60000
  })

  // Terms api init
  const termsApi = apisauce.create({
    // base URL is read from the "constructor"
    // 60 second timeout...
    timeout: 60000
  })

  // Wrappers
  api.addAsyncRequestTransform(bindToken(config)) // Bind token on Authorization header
  api.addAsyncResponseTransform(responseWrapper(config)) // Error handling wrapper
  moodboardApi.addAsyncRequestTransform(bindToken(config)) // Bind token on Authorization header
  moodboardApi.addAsyncResponseTransform(responseWrapper(config)) // Error handling wrapper
  termsApi.addAsyncResponseTransform(responseWrapper(config)) // Error handling wrapper

  // Local API for test
  // localApi.addAsyncRequestTransform(bindToken(config)) // Bind token on Authorization header
  // localApi.addAsyncResponseTransform(responseWrapper(config)) // Error handling wrapper

  // Requests
  apiHandlers.getMe = () => api.get('/user/me')
  apiHandlers.getClientsList = () => api.get('/user')
  apiHandlers.patchMe = body => api.patch('/user', body)
  apiHandlers.createUser = body => api.post('/user', body)
  apiHandlers.getClientById = userId => api.get(`/user/${userId}`)
  apiHandlers.patchClient = (userId, body) =>
    api.patch(`/user?id=${userId}`, body)
  apiHandlers.deleteClient = userId =>
    api.delete('/user', {}, { data: { userId } })

  apiHandlers.postSilhouetteId = (userId, body) =>
    api.post(`/user/${userId}/media/profile/silhouette`, body)
  apiHandlers.deleteSilhouetteId = userId =>
    api.delete(`/user/${userId}/media/profile/silhouette`)
  apiHandlers.postProfileImage = (userId, body, rank, contentType) =>
    api.post(`/user/${userId}/media/profile/image?rankNumber=${rank}`, body, {
      headers: { 'content-type': contentType || 'image/png' }
    })

  apiHandlers.deleteAllProfileImages = userId =>
    api.delete(`/user/${userId}/media/profile/image/all`)
  apiHandlers.deleteProfileImage = (userId, body) =>
    api.delete(`/user/${userId}/media/profile/image`, {}, { data: body })
  apiHandlers.orderProfileImages = (userId, body) =>
    api.post(`/user/${userId}/media/profile/image/order`, body)

  apiHandlers.postPersonalColoringId = (userId, body) =>
    api.post(`/user/${userId}/media/personalcoloring/assign`, body)
  apiHandlers.postPersonalColoringImage = (userId, body, contentType) =>
    api.post(`/user/${userId}/media/personalcoloring/image`, body, {
      headers: { 'content-type': contentType || 'image/png' }
    })
  apiHandlers.deletePersonalColoringById = (userId, body) =>
    api.delete(
      `/user/${userId}/media/personalcoloring/image`,
      {},
      { data: body }
    ) // Delete needs extra params to use body
  apiHandlers.rankPersonalColoringImages = (userId, body) =>
    api.patch(`/user/${userId}/media/personalcoloring/image/rank`, body)

  apiHandlers.postContrastId = (userId, body) =>
    api.post(`/user/${userId}/media/contrast/assign`, body)
  apiHandlers.postContrastImage = (userId, body, contentType) =>
    api.post(`/user/${userId}/media/contrast/image`, body, {
      headers: { 'content-type': contentType || 'image/png' }
    })
  apiHandlers.deleteContrastById = (userId, body) =>
    api.delete(`/user/${userId}/media/contrast/image`, {}, { data: body }) // Delete needs extra params to use body

  apiHandlers.removePersonalColoring = userId =>
    api.post(`/user/${userId}/media/personalcoloring/remove`)
  apiHandlers.removeContrast = userId =>
    api.post(`/user/${userId}/media/contrast/remove`)
  apiHandlers.rankContrastImages = (userId, body) =>
    api.patch(`/user/${userId}/media/contrast/image/rank`, body)

  apiHandlers.postShoppingListItem = (
    userId,
    name,
    category,
    brand,
    link,
    image,
    contentType
  ) =>
    api.post(
      `/user/${userId}/media/shoplist/template`,
      {
        name: name,
        category: category,
        brand: brand,
        link: link,
        image: image
      },
      {
        headers: { 'content-type': contentType || 'image/png' }
      }
    )
  apiHandlers.getShoppingListItem = userId =>
    api.get(`/user/${userId}/media/shoplist/template`)

  apiHandlers.deleteAllShoppingListTemplates = userId =>
    api.delete(`/user/${userId}/media/shoplist/template/all`)
  apiHandlers.rankShoppingListTemplateItem = (userId, body) =>
    api.patch(`/user/${userId}/media/shoplist/template/rank`, body)
  apiHandlers.getShoppingListTemplateItem = (userId, itemId) =>
    api.get(`/user/${userId}/media/shoplist/template/${itemId}`)
  apiHandlers.updateShoppingListTemplateItem = (
    itemId,
    name,
    category,
    brand,
    link,
    image,
    contentType
  ) =>
    api.patch(`/user/media/shoplist/template/${itemId}`, {
      name: name,
      category: category,
      brand: brand,
      link: link,
      image: image
    })
  apiHandlers.deleteShoppingListTemplateItem = (userId, itemId) =>
    api.delete(`/user/${userId}/media/shoplist/template/${itemId}`)

  apiHandlers.postShopListText = (userId, body) =>
    api.post(`/user/${userId}/media/shoplist/text`, body)
  apiHandlers.postShopListImage = (userId, body, contentType) =>
    api.post(`/user/${userId}/media/shoplist/image`, body, {
      headers: { 'content-type': contentType || 'image/png' }
    })
  apiHandlers.deleteShopListImageById = (userId, body) =>
    api.delete(`/user/${userId}/media/shoplist/image`, {}, { data: body }) // Delete needs extra params to use body
  apiHandlers.deleteAllShopListImages = userId =>
    api.delete(`/user/${userId}/media/shoplist/image/all`)
  apiHandlers.rankShopListImages = (userId, body) =>
    api.patch(`/user/${userId}/media/shoplist/image/rank`, body)

  apiHandlers.postMoodboard = (userId, body, contentType) =>
    moodboardApi.post(`/user/${userId}/media/moodboard`, body, {
      headers: { 'content-type': contentType || 'application/pdf' }
    })
  apiHandlers.getMoodboard = userId =>
    api.get(`/user/${userId}/media/moodboard`)
  apiHandlers.deleteMoodboard = userId =>
    api.delete(`/user/${userId}/moodboard`)
  apiHandlers.createMoodboardCategory = (userId, body) =>
    api.post(`/user/${userId}/moodboard/template/category`, body)
  apiHandlers.updateMoodboardCategory = (userId, categoryId, body) =>
    api.patch(`/user/${userId}/moodboard/template/category/${categoryId}`, body)
  apiHandlers.getMoodboardCategory = userId =>
    api.get(`/user/${userId}/moodboard/template/all`)
  apiHandlers.deleteMoodboardCategory = (userId, id) =>
    api.delete(`/user/${userId}/moodboard/template/category/${id}`)
  apiHandlers.copyMoodboardCategoryFromClient = (userId, body) =>
    api.post(`/user/${userId}/moodboard/template/category/copy`, body)
  apiHandlers.createTemplateItem = (userId, body) =>
    api.post(`/user/${userId}/moodboard/template/item`, body)
  apiHandlers.deleteTemplateItem = (userId, id) =>
    api.delete(`/user/${userId}/moodboard/template/item/${id}`)
  apiHandlers.updateTemplateItem = (userId, id, body) =>
    api.patch(`/user/${userId}/moodboard/template/item/${id}`, body)
  apiHandlers.releaseMoodboard = userId =>
    api.post(`/user/${userId}/moodboard/template/release/all`)
  apiHandlers.hideMoodboard = userId =>
    api.post(`/user/${userId}/moodboard/template/hide/all`)
  apiHandlers.rankMoodboard = (userId, body) =>
    api.patch(`/user/${userId}/moodboard/template/category/rank`, body)
  apiHandlers.deleteMoodboardCategoryAll = userId =>
    api.delete(`/user/${userId}/moodboard/template/category/all`)
  apiHandlers.getSimilarClients = (userId, body) =>
    api.post(`/user/${userId}/moodboard/template/category/similarity`, body)

  apiHandlers.addCategoryCoverImage = (userId, id, body) =>
    api.patch(`/user/${userId}/moodboard/template/category/${id}/image`, body)
  apiHandlers.deleteCategoryCoverImage = (userId, id) =>
    api.delete(`/user/${userId}/moodboard/template/category/${id}/image`)

  apiHandlers.postClosetImage = (userId, body, contentType, axiosConfig = {}) =>
    api.post(`/user/${userId}/media/closet`, body, {
      headers: { 'content-type': contentType || 'image/png' },
      ...axiosConfig
    })

  apiHandlers.postClosetImageWithText = (
    userId,
    body,
    contentType,
    axiosConfig = {}
  ) =>
    api.post(`/user/${userId}/media/closet/image-and-text`, body, {
      headers: { 'content-type': contentType || 'image/png' },
      ...axiosConfig
    })

  apiHandlers.patchClosetImage = (userId, id, body) =>
    api.patch(`/user/${userId}/media/closet/image/${id}`, body)

  apiHandlers.rankClosetImages = (userId, body) =>
    api.patch(`/user/${userId}/media/closet/image/rank`, body)

  apiHandlers.deleteClosetImagesByIds = (userId, body) =>
    api.delete(`/user/${userId}/media/closet`, {}, { data: body }) // Delete needs extra params to use body
  apiHandlers.postClosetImageCategory = (userId, body) =>
    api.post(`/user/${userId}/media/closet/category`, body)
  apiHandlers.patchClosetImageCategory = (userId, id, body) =>
    api.patch(`/user/${userId}/media/closet/category/${id}`, body)
  apiHandlers.deleteClosetImageCategory = (userId, id) =>
    api.delete(`/user/${userId}/media/closet/category/${id}`)

  apiHandlers.rankClosetImageCategory = (userId, body) =>
    api.patch(`/user/${userId}/media/closet/category/rank`, body)

  apiHandlers.postAgendaEvent = body => api.post('/agenda/event', body)
  apiHandlers.patchAgendaEvent = (id, body) =>
    api.patch(`/agenda/event/${id}`, body)
  apiHandlers.getNextAgendaEvents = () => api.get(`/agenda/events/next`)
  apiHandlers.getAgendaEventById = id => api.get(`/agenda/event/${id}`)
  apiHandlers.deleteAgendaEvent = id => api.delete(`/agenda/event/${id}`)

  apiHandlers.postTextNote = (userId, body) =>
    api.post(`/consultant/note?userId=${userId}`, body, {
      headers: { 'content-type': 'application/text' }
    })
  apiHandlers.patchTextNote = (userId, body) =>
    api.patch(`/consultant/note/${userId}`, body, {
      headers: { 'content-type': 'application/text' }
    })
  apiHandlers.getConsultantProfile = () => api.get('/consultant/profile')
  apiHandlers.putConsultantLogo = (body, contentType) =>
    api.put('/consultant/profile/logo', body, {
      headers: { 'content-type': contentType || 'image/png' }
    })

  apiHandlers.loginFb = body => api.post('/auth/fb', body)
  apiHandlers.localLogin = body => api.post('/auth/local', body)
  apiHandlers.resetPass = body => api.post('/auth/reset/request', body)
  apiHandlers.getPasswordLink = email =>
    api.post(`/auth/setpassword/link`, { email })

  apiHandlers.listPlans = () => api.get('/plan?orderBy=rankNumber&order=ASC')
  apiHandlers.getPlan = id => api.get(`/plan/${id}`)
  apiHandlers.getPlanPeriod = id => api.get(`/plan/period/${id}`)

  apiHandlers.getSubscription = () => api.get('/subscription/current')
  apiHandlers.postSubscription = ({
    paymentMethod,
    paymentMethodIdentifier,
    planIdentifier,
    couponCode,
    installmentsAmount
  }) =>
    api.post('/subscription', {
      paymentMethod,
      paymentMethodIdentifier,
      planIdentifier,
      couponCode,
      installmentsAmount
    })

  apiHandlers.getCurrentActivePaymentService = () =>
    api.get('/payment/active-payment-service')
  apiHandlers.postCardIugu = body => api.post('/payment/iugu/card', body)

  // When registering a card we do not ask for the date of birth, so you have this hardcode below
  apiHandlers.postCard = ({
    hash,
    fullname,
    birthdate = '01/01/1991',
    cpf,
    brand,
    last4
  }) =>
    api.post('/payment/customer/card', {
      hash,
      fullname,
      birthdate,
      cpf,
      brand,
      last4
    })
  apiHandlers.deleteCard = id => api.delete(`/payment/customer/card/${id}`)
  apiHandlers.listCards = () => api.get('/payment/customer/card')

  apiHandlers.listVideos = () => api.get('/video')

  apiHandlers.listLevels = () => api.get('/gamification/level')
  apiHandlers.getUserLevel = () => api.get('/gamification/user/level')
  apiHandlers.changeLastRewardLevelShowed = lastRewardLevelShowedId =>
    api.patch('/gamification/user/level/lastRewardLevelShowed', {
      lastRewardLevelShowedId
    })
  apiHandlers.getLevelReward = levelId =>
    api.get(`/gamification/level/${levelId}/reward`)

  apiHandlers.getAchievementsList = () =>
    api.get('/gamification/user/achievement')
  apiHandlers.postSeenAchievementCompletion = id =>
    api.post(`/gamification/user/achievement/${id}/complete`)
  apiHandlers.postCompleteGoal = goalId =>
    api.post('/gamification/user/goal/complete', {
      goals: [
        {
          goalId,
          completedDates: [moment().format('YYYY-MM-DD HH:mm:ss')]
        }
      ]
    })

  apiHandlers.postCouponCode = couponCode =>
    api.post('/coupon/usage', couponCode)

  apiHandlers.getAddressByCep = cep => cepApi(cep)

  apiHandlers.getPrivacyPolicy = () =>
    termsApi.get(`${AppConfig.privacyPolicyURL}`)
  apiHandlers.updateLastPrivacyPolicyVersionSeen = privacyPolicyVersion =>
    api.patch('/user/privacyPolicy', { privacyPolicyVersion })

  apiHandlers.getClientListSixMonths = (monthsInactive, weeksShown) =>
    api.get(
      `/user/finalized-clients?monthsInactive=${monthsInactive}&weeksShown=${weeksShown}`
    )

  apiHandlers.upsertConsultantTimeSlotRecurrences = body =>
    api.put(`/calendar/timeslot/recurrence`, body)
  apiHandlers.getConsultantTimeSlotRecurrences = () =>
    api.get(`/calendar/timeslot/recurrence`)

  apiHandlers.getConsultantMeetings = () => api.get(`/calendar/meeting`)
  apiHandlers.getConsultantMeetingsHasSeenMeeting = (
    status,
    consultantHasSeenMeeting
  ) =>
    api.get(
      `/calendar/meeting?meeting.status=${status}&meeting.consultantHasSeenMeeting=${consultantHasSeenMeeting}`
    )
  apiHandlers.updateConsultantHasSeenMeeting = id =>
    api.patch(`/calendar/meeting/${id}/viewed`)
  apiHandlers.getClientStyle = selectedClientId =>
    api.get(`/user/${selectedClientId}/media/style`)
  apiHandlers.createClientStyle = (selectedClientId, body) =>
    api.post(`/user/${selectedClientId}/media/style`, body)
  apiHandlers.updateClientStyle = (selectedClientId, body) =>
    api.patch(`/user/${selectedClientId}/media/style`, body)
  apiHandlers.deleteClientStyle = selectedClientId =>
    api.delete(`/user/${selectedClientId}/media/style`)

  apiHandlers.getConsultantServices = () =>
    api.get(`/financial/service/consultant`)
  apiHandlers.getConsultantServiceById = serviceId =>
    api.get(`/financial/service/${serviceId}/consultant`)
  apiHandlers.createConsultantService = body =>
    api.post(`/financial/service/consultant`, body)
  apiHandlers.updateConsultantServiceById = (serviceId, body) =>
    api.patch(`/financial/service/${serviceId}/consultant`, body)
  apiHandlers.deleteConsultantService = serviceId =>
    api.delete(`/financial/service/${serviceId}/consultant`)

  apiHandlers.createClientService = body =>
    api.post(`/financial/service/client`, body)
  apiHandlers.getClientService = clientId =>
    api.get(`/financial/service/client/${clientId}`)
  apiHandlers.getAllClientServices = () => api.get(`/financial/service/client`)
  apiHandlers.deleteClientService = serviceId =>
    api.delete(`/financial/service/${serviceId}/client`)

  apiHandlers.getPeriodRevenue = (startDate, endDate) =>
    api.get(
      `/financial/report/period-revenue?startDate=${startDate}&endDate=${endDate}`
    )

  apiHandlers.getPeriodRevenueList = (startDate, endDate) =>
    api.get(
      `/financial/report/period-revenue-list?startDate=${startDate}&endDate=${endDate}`
    )
  apiHandlers.getPeriodExpenseList = (startDate, endDate) =>
    api.get(
      `/financial/report/period-expense-list?startDate=${startDate}&endDate=${endDate}`
    )

  apiHandlers.createServiceBilling = body =>
    api.post(`/financial/billing`, body)
  apiHandlers.getServiceBillingById = billingId =>
    api.get(`/financial/billing/${billingId}/consultant`)
  apiHandlers.getServiceBillingList = () =>
    api.get(`/financial/billing/consultant`)
  apiHandlers.updateServiceBilling = (billingId, body) =>
    api.patch(`/financial/billing/${billingId}/consultant`, body)
  apiHandlers.cancelServiceBilling = billingId =>
    api.patch(`/financial/billing/${billingId}/consultant/cancel`)

  apiHandlers.blockClient = body => api.post(`/financial/block/client`, body)
  apiHandlers.consultantReleaseClient = clientId =>
    api.post(`/financial/block/client/${clientId}/release/consultant`)
  apiHandlers.consultantGetBlockedClients = () =>
    api.get(`/financial/block/client/consultant`)

  apiHandlers.createExpense = body =>
    api.post(`/financial/expense/consultant`, body)

  apiHandlers.deleteExpense = expenseId =>
    api.delete(`/financial/expense/${expenseId}/consultant`)

  apiHandlers.getColoringProducts = coloring =>
    api.get(`product/user-products?coloringType=${coloring}`)

  apiHandlers.createIuguPaymentToken = body =>
    iuguApi.post(`/payment_token`, body)

  apiHandlers.getRecommendationInfo = () => api.get(`user/recommendation-info`)
}

const apiHandlers = {}

export default apiHandlers
