import React from 'react'
import Simple from '../Components/Popup/Simple'
import { connect } from 'react-redux'
import YesNo from '../Components/Popup/YesNo'
import Combinou from '../Components/Popup/Combinou'
import YesNoWithInputConfirmation from '../Components/Popup/YesNoWithInputConfirmation'
import MapOptions from '../Components/Popup/MapOptions'
import Carousel from '../Components/Popup/Carousel'

class Popup extends React.Component {
  types = {
    simple: Simple,
    yesNo: YesNo,
    combinou: Combinou,
    yesNoWithInputConfirmation: YesNoWithInputConfirmation,
    mapOptions: MapOptions,
    carousel: Carousel
  }

  render () {
    const { type, children, props } = this.props.popup
    const Component = this.types[type]

    if (type === null) {
      return null
    } else if (Component) {
      return <Component {...props}>{children}</Component>
    }
    throw new Error('Invalid type of popup')
  }
}

const mapStateToProps = state => ({
  popup: state.settings.popup
})

export default connect(mapStateToProps, null)(Popup)
