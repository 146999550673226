import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

class GamificationHandler extends Component {
  componentDidMount () {
    // Checks when you are already logged in
    if (this.props.token && !this.props.firstLogin) {
      const hasSomeUnviewedCompletion = this.props.achievementsList.some(
        ({ status }) => status === 'unviewedCompletion'
      )
      const isLevelUp =
        this.props.level &&
        this.props.level.levelId > this.props.level.lastRewardLevelShowedId
      if (hasSomeUnviewedCompletion) {
        this.props.history.push('/desafios/concluido')
      } else if (isLevelUp) {
        this.props.history.push('/recompensa')
      }
    }
  }
  componentDidUpdate (prevProps) {
    const hasSomeUnviewedCompletion = this.props.achievementsList.some(
      ({ status }) => status === 'unviewedCompletion'
    )
    const isLevelUp =
      this.props.level.levelId > this.props.level.lastRewardLevelShowedId

    if (!prevProps.token && this.props.token && !this.props.firstLogin) {
      if (hasSomeUnviewedCompletion) {
        this.props.history.push('/desafios/concluido')
      } else if (isLevelUp) {
        this.props.history.push('/recompensa')
      }
    } else if (
      (prevProps.achievementsList !== this.props.achievementsList ||
        prevProps.level !== this.props.level ||
        (prevProps.firstLogin && !this.props.firstLogin)) &&
      !this.props.firstLogin
    ) {
      if (hasSomeUnviewedCompletion) {
        this.props.history.push('/desafios/concluido')
      } else if (isLevelUp) {
        this.props.history.push('/recompensa')
      }
    }
  }

  render () {
    return this.props.children || null
  }
}

const mapSateToProps = state => ({
  token: state.user.token,
  achievementsList: state.user.achievementsList,
  firstLogin: state.user.firstLogin,
  level: state.user.level
})

export default connect(mapSateToProps, null)(withRouter(GamificationHandler))
