import { createReducer, createActions } from 'reduxsauce'
import {
  resolveDevice,
  resolveOrientation
} from '../Services/DeviceScreenListener'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  startup: null,
  setClientsListNameFilter: ['clientsListNameFilter'],
  setClientsListStateFilter: ['clientsListStateFilter'],
  setUpsertClientDataTemp: ['upsertClientDataTemp'],
  setUpsertClientDataTempServices: ['upsertClientDataTempServices'],
  removeUpsertClientDataTempServices: ['upsertClientDataTempServicesIndex'],
  setNewClientFieldsAlreadyAdded: ['newClientFieldsAlreadyAdded'],
  clearUpsertClientDataTemp: [],
  setDevice: ['device'],
  setOrientation: ['orientation'],
  setRedirectPushParams: ['redirectPushParams'],
  showPositiveToast: ['text', 'duration', 'callback'],
  showNegativeToast: ['text', 'duration', 'callback'],
  showNeutralToast: ['text', 'duration', 'callback'],
  setPositiveToastShow: ['show'],
  setSignUpFirstStepData: ['signUpFirstStepData'],
  setShowPhoneRegisterModal: ['shouldShowPhoneModal'],
  setNegativeToastShow: ['show'],
  setNeutralToastShow: ['show'],
  setLoading: ['loading'],
  setBurgerMenuOpen: ['isOpen'],
  openPopup: ['data'],
  setPopupLoading: ['loading'],
  closePopup: [],
  pushToMoodboardUploadingQueue: ['clientId'],
  popFromMoodboardUploadingQueue: ['clientIds'],
  toogleBurgerMenuOpen: [],
  setClientTemplateSilhouette: ['clientTemplateSilhouette'],
  setStatusClient: ['statusClient']
})

export const SettingsTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = {
  moodboardUploadingQueue: [], // Keeps clients whose moodboard is being uploaded
  clientsListNameFilter: '',
  clientsListStateFilter: 'active',
  upsertClientDataTemp: {
    country: {
      name: {
        common: 'Brazil',
        official: 'Federative Republic of Brazil',
        native: {
          por: {
            official: 'Rep\u00fablica Federativa do Brasil',
            common: 'Brasil'
          }
        }
      },
      tld: ['.br'],
      cca2: 'BR',
      ccn3: '076',
      cca3: 'BRA',
      cioc: 'BRA',
      independent: true,
      status: 'officially-assigned',
      currency: ['BRL'],
      callingCode: ['55'],
      capital: ['Bras\u00edlia'],
      altSpellings: [
        'BR',
        'Brasil',
        'Federative Republic of Brazil',
        'Rep\u00fablica Federativa do Brasil'
      ],
      region: 'Americas',
      subregion: 'South America',
      languages: { por: 'Portuguese' },
      translations: {
        ces: {
          official: 'Brazilsk\u00e1 federativn\u00ed republika',
          common: 'Braz\u00edlie'
        },
        cym: { official: 'Gweriniaeth Ffederal Brasil', common: 'Brasil' },
        deu: {
          official: 'F\u00f6derative Republik Brasilien',
          common: 'Brasilien'
        },
        fra: {
          official: 'R\u00e9publique f\u00e9d\u00e9rative du Br\u00e9sil',
          common: 'Br\u00e9sil'
        },
        hrv: { official: 'Savezne Republike Brazil', common: 'Brazil' },
        ita: {
          official: 'Repubblica federativa del Brasile',
          common: 'Brasile'
        },
        jpn: {
          official: '\u30d6\u30e9\u30b8\u30eb\u9023\u90a6\u5171\u548c\u56fd',
          common: '\u30d6\u30e9\u30b8\u30eb'
        },
        nld: {
          official: 'Federale Republiek Brazili\u00eb',
          common: 'Brazili\u00eb'
        },
        por: {
          official: 'Rep\u00fablica Federativa do Brasil',
          common: 'Brasil'
        },
        rus: {
          official:
            '\u0424\u0435\u0434\u0435\u0440\u0430\u0442\u0438\u0432\u043d\u0430\u044f \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0411\u0440\u0430\u0437\u0438\u043b\u0438\u044f',
          common: '\u0411\u0440\u0430\u0437\u0438\u043b\u0438\u044f'
        },
        slk: {
          official: 'Braz\u00edlska federat\u00edvna republika',
          common: 'Braz\u00edlia'
        },
        spa: {
          official: 'Rep\u00fablica Federativa del Brasil',
          common: 'Brasil'
        },
        fin: { official: 'Brasilian liittotasavalta', common: 'Brasilia' },
        est: { official: 'Brasiilia Liitvabariik', common: 'Brasiilia' },
        zho: {
          official: '\u5df4\u897f\u8054\u90a6\u5171\u548c\u56fd',
          common: '\u5df4\u897f'
        },
        pol: { official: 'Federacyjna Republika Brazylii', common: 'Brazylia' }
      },
      latlng: [-10, -55],
      demonym: 'Brazilian',
      landlocked: false,
      borders: [
        'ARG',
        'BOL',
        'COL',
        'GUF',
        'GUY',
        'PRY',
        'PER',
        'SUR',
        'URY',
        'VEN'
      ],
      area: 8515767,
      flag: '\ud83c\udde7\ud83c\uddf7'
    }
  },
  upsertClientDataTempServices: [],
  newClientFieldsAlreadyAdded: false,
  isMobile: resolveDevice({ maxMobileWidth: 768 }) === 'mobile',
  device: resolveDevice({ maxMobileWidth: 768 }),
  orientation: resolveOrientation(),
  signUpFirstStepData: null,
  positiveToastShow: null,
  redirectPushParams: null,
  loading: false,
  negativeToastShow: null,
  shouldShowPhoneModal: false,
  neutralToastShow: null,
  popup: { type: null, props: null, children: null },
  isBurgerMenuOpen: false,
  clientTemplateSilhouette: 'template',
  statusClient: 'Informacoes'
}

/* ------------- Reducers ------------- */

export const setClientsListStateFilter = (
  state,
  { clientsListStateFilter }
) => {
  return { ...state, clientsListStateFilter }
}

export const setClientsListNameFilter = (state, { clientsListNameFilter }) => {
  return { ...state, clientsListNameFilter }
}

export const setDevice = (state, { device }) => {
  return { ...state, device, isMobile: device === 'mobile' }
}

export const setOrientation = (state, { orientation }) => {
  return { ...state, orientation }
}

export const setLoading = (state, { loading }) => {
  return { ...state, loading }
}

export const setRedirectPushParams = (state, { redirectPushParams }) => {
  return { ...state, redirectPushParams }
}

export const setSignUpFirstStepData = (state, { signUpFirstStepData }) => {
  return { ...state, signUpFirstStepData }
}

export const setPositiveToastShow = (state, { show }) => {
  return { ...state, positiveToastShow: show }
}

export const setNegativeToastShow = (state, { show }) => {
  return { ...state, negativeToastShow: show }
}

export const setNeutralToastShow = (state, { show }) => {
  return { ...state, neutralToastShow: show }
}

export const openPopup = (state, { data }) => {
  const { type, props, children } = data
  return { ...state, popup: { children, props, type } }
}

export const setPopupLoading = (state, { loading }) => {
  return {
    ...state,
    popup: {
      ...state.popup,
      props: { ...state.popup.props, isLoading: loading }
    }
  }
}

export const closePopup = state => {
  return { ...state, popup: INITIAL_STATE.popup }
}

export const setBurgerMenuOpen = (state, { isOpen }) => {
  return { ...state, isBurgerMenuOpen: isOpen }
}

export const setShowPhoneRegisterModal = (state, { shouldShowPhoneModal }) => {
  return { ...state, shouldShowPhoneModal }
}

export const toogleBurgerMenuOpen = state => {
  return { ...state, isBurgerMenuOpen: !state.isBurgerMenuOpen }
}

export const setUpsertClientDataTemp = (state, { upsertClientDataTemp }) => {
  return {
    ...state,
    upsertClientDataTemp: {
      ...state.upsertClientDataTemp,
      ...upsertClientDataTemp
    }
  }
}

export const setUpsertClientDataTempServices = (
  state,
  { upsertClientDataTempServices }
) => {
  return {
    ...state,
    upsertClientDataTempServices: [
      ...state.upsertClientDataTempServices,
      upsertClientDataTempServices
    ]
  }
}

export const removeUpsertClientDataTempServices = (
  state,
  { upsertClientDataTempServicesIndex }
) => {
  const upsertClientDataTempServices = state.upsertClientDataTempServices
  upsertClientDataTempServices.splice(upsertClientDataTempServicesIndex, 1)
  return {
    ...state,
    upsertClientDataTempServices: upsertClientDataTempServices
  }
}

export const setNewClientFieldsAlreadyAdded = (
  state,
  { newClientFieldsAlreadyAdded }
) => {
  return {
    ...state,
    newClientFieldsAlreadyAdded: newClientFieldsAlreadyAdded
  }
}

export const clearUpsertClientDataTemp = state => {
  return {
    ...state,
    upsertClientDataTemp: INITIAL_STATE.upsertClientDataTemp,
    upsertClientDataTempServices: INITIAL_STATE.upsertClientDataTempServices
  }
}

// Responsible for keeping ids of clients whose moodboard is being uploaded.
// When a valid `moodboard` is fetched for that user, it's ID must be removed
// from this list
export const pushToMoodboardUploadingQueue = (state, { clientId }) => {
  const moodboardUploadingQueue = [...state.moodboardUploadingQueue, clientId]

  return { ...state, moodboardUploadingQueue: _.uniq(moodboardUploadingQueue) }
}

// Responsible from removing client's ids from moodboardUploadingQueue when a valid `moodboard`
// is fetched for that user
export const popFromMoodboardUploadingQueue = (state, { clientIds }) => {
  // Accepts input as array, or single value, but transforms to array for simplicity
  const ids = Array.isArray(clientIds) ? clientIds : [clientIds]

  const moodboardUploadingQueue = _.without(
    state.moodboardUploadingQueue,
    ...ids
  )

  return { ...state, moodboardUploadingQueue }
}

export const setClientTemplateSilhouette = (
  state,
  { clientTemplateSilhouette }
) => {
  return { ...state, clientTemplateSilhouette }
}

export const setStatusClient = (state, { statusClient }) => {
  return { ...state, statusClient: statusClient }
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_CLIENTS_LIST_NAME_FILTER]: setClientsListNameFilter,
  [Types.SET_CLIENTS_LIST_STATE_FILTER]: setClientsListStateFilter,
  [Types.SET_UPSERT_CLIENT_DATA_TEMP]: setUpsertClientDataTemp,
  [Types.SET_UPSERT_CLIENT_DATA_TEMP_SERVICES]: setUpsertClientDataTempServices,
  [Types.REMOVE_UPSERT_CLIENT_DATA_TEMP_SERVICES]: removeUpsertClientDataTempServices,
  [Types.SET_NEW_CLIENT_FIELDS_ALREADY_ADDED]: setNewClientFieldsAlreadyAdded,
  [Types.CLEAR_UPSERT_CLIENT_DATA_TEMP]: clearUpsertClientDataTemp,
  [Types.SET_SHOW_PHONE_REGISTER_MODAL]: setShowPhoneRegisterModal,
  [Types.SET_LOADING]: setLoading,
  [Types.SET_SIGN_UP_FIRST_STEP_DATA]: setSignUpFirstStepData,
  [Types.SET_DEVICE]: setDevice,
  [Types.SET_REDIRECT_PUSH_PARAMS]: setRedirectPushParams,
  [Types.SET_ORIENTATION]: setOrientation,
  [Types.SET_POSITIVE_TOAST_SHOW]: setPositiveToastShow,
  [Types.SET_NEGATIVE_TOAST_SHOW]: setNegativeToastShow,
  [Types.SET_NEUTRAL_TOAST_SHOW]: setNeutralToastShow,
  [Types.SET_BURGER_MENU_OPEN]: setBurgerMenuOpen,
  [Types.OPEN_POPUP]: openPopup,
  [Types.CLOSE_POPUP]: closePopup,
  [Types.SET_POPUP_LOADING]: setPopupLoading,
  [Types.TOOGLE_BURGER_MENU_OPEN]: toogleBurgerMenuOpen,
  [Types.PUSH_TO_MOODBOARD_UPLOADING_QUEUE]: pushToMoodboardUploadingQueue,
  [Types.POP_FROM_MOODBOARD_UPLOADING_QUEUE]: popFromMoodboardUploadingQueue,
  [Types.SET_CLIENT_TEMPLATE_SILHOUETTE]: setClientTemplateSilhouette,
  [Types.SET_STATUS_CLIENT]: setStatusClient
})
