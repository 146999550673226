import React from 'react'
import { withRouter } from 'react-router-dom'
import { Text, TouchableOpacity, StyleSheet, ViewPropTypes } from 'react-native'
import PropTypes from 'prop-types'
import Colors from '../Themes/Colors'

const style = StyleSheet.create({
  text: {
    color: Colors.primary,
    fontSize: 20,
    textDecorationLine: 'underline'
  }
})

/** @typedef { Object } LinkProps
@prop { string } children
@prop { () => void } [onPress]
@prop { import('react-native').TextStyle } [style]
@prop { import('react-native').ViewStyle } [containerStyle]
@extends { React.Component<LinkProps> }
*/
function Link ({
  children,
  onPress,
  style: propStyle,
  containerStyle,
  ...remainingProps
}) {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={containerStyle}
      {...remainingProps}
    >
      <Text style={[style.text, propStyle]}>{children}</Text>
    </TouchableOpacity>
  )
}

Link.propTypes = {
  children: ViewPropTypes.children.isRequired,
  onPress: PropTypes.func,
  style: Text.propTypes.style,
  containerStyle: ViewPropTypes.style
}

export default withRouter(Link)
