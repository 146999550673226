import { Platform, Linking } from 'react-native'
import { UAParser } from 'ua-parser-js'

import AppConfig from '../Config/AppConfig'

import Images from '../Themes/Images'

const { device } = new UAParser().getResult()
const isMobile = ['mobile', 'tablet'].includes(device.type)

export const s3KeyToURI = key => {
  return `https://${AppConfig.s3Bucket}.s3.${AppConfig.s3Region}.amazonaws.com/${key}`
}

export const base64MimeType = encoded => {
  if (typeof encoded !== 'string') {
    return null
  }

  const mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/)

  if (mime && mime.length) {
    return mime[1]
  } else {
    return null
  }
}

export const convertBlobToBase64 = blob =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onerror = reject
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.readAsDataURL(blob)
  })

export const convertUrlToBase64 = imgUrl =>
  new Promise(resolve => {
    let img = new Image()

    // onload fires when the image is fully loadded, and has width and height
    img.onload = function () {
      let imageSize
      let finalDataURL
      let compressionLevel = 0

      function getCompressedImage (compression) {
        var newImgWidth = Math.floor(img.width / compression)
        var newImgHeight = Math.floor(img.height / compression)

        var canvas = document.createElement('canvas')
        canvas.width = newImgWidth
        canvas.height = newImgHeight
        var ctx = canvas.getContext('2d')
        ctx.drawImage(img, 0, 0, newImgWidth, newImgHeight)
        var dataURL = canvas.toDataURL('image/png')

        finalDataURL = dataURL
        return encodeURI(dataURL).split(/%..|./).length - 1
      }

      while (!imageSize || imageSize > 200 * 1024 || compressionLevel > 100) {
        imageSize = getCompressedImage(1 + compressionLevel * 0.3)
        compressionLevel++
      }

      resolve(finalDataURL)
    }

    // set attributes and src
    img.setAttribute('crossOrigin', 'anonymous') //
    img.src = imgUrl
  })

export const resolveClosetType = closet => {
  const firstPhoto = closet && closet[0]

  if (!firstPhoto) return null

  return firstPhoto.categoryId == null ? 'unique' : 'category'
}

export const silhouetteTypes = [
  {
    id: 1,
    label: 'Triângulo',
    image: require('../Images/templates/silhouette/silhouette1.jpeg')
  },
  {
    id: 2,
    label: 'Oval',
    image: require('../Images/templates/silhouette/silhouette2.jpeg')
  },
  {
    id: 3,
    label: 'Triângulo invertido',
    image: require('../Images/templates/silhouette/silhouette3.jpeg')
  },
  {
    id: 4,
    label: 'Retângulo',
    image: require('../Images/templates/silhouette/silhouette4.jpeg')
  },
  {
    id: 5,
    label: 'Ampulheta',
    image: require('../Images/templates/silhouette/silhouette5.jpeg')
  },
  {
    id: 6,
    label: '8',
    image: require('../Images/templates/silhouette/silhouette6.jpeg')
  }
]

export const resolveFeaturesPlan = object => {
  const features = {
    feature_onlinePallete: 'Paleta Online',
    feature_virtualCloset: 'Closet Virtual',
    feature_birthdayWarning: 'Lembrete de Aniversários',
    feature_completeMoodboard: 'Moodboard Completo',
    feature_content: 'Conteúdos exclusivos na plataforma',
    feature_silhouette: 'Templates de silhueta',
    feature_customerLimit:
      object.feature_customerLimit != null
        ? `Cadastro de até ${object.feature_customerLimit} clientes`
        : 'Cadastro Ilimitado de Clientes',
    feature_premiumColoring: 'Coloração Premium - Looks / Cabelo e Maquiagem',
    feature_website: 'Desenvolvimento de site, domínio e conta de e-mail'
  }

  const activeFeatures = Object.entries(object)
    .filter(item => item[1] !== 0)
    .map(item => item[0])

  return activeFeatures.map(item => features[item]).filter(item => item) // Removes the undefined
}

const colorMatcher = {
  1: ['primavera', 'quente'],
  2: ['primavera', 'brilhante'],
  3: ['primavera', 'clara'],
  4: ['outono', 'suave'],
  5: ['outono', 'escuro'],
  6: ['outono', 'quente'],
  7: ['inverno', 'brilhante'],
  8: ['inverno', 'frio'],
  9: ['inverno', 'escuro'],
  10: ['verao', 'frio'],
  11: ['verao', 'claro'],
  12: ['verao', 'suave']
}

const constrastMatcher = {
  0: 'main',
  1: 'alto',
  2: 'medio',
  3: 'baixoclaro',
  4: 'baixoescuro',
  5: 'medioalto',
  6: 'mediobaixo'
}

export const COLUMN_NUMBERS = 3

export const getColors = () => [
  { id: 1, label: 'Primavera Quente', background: '#dfc65f' },
  { id: 2, label: 'Primavera Brilhante', background: '#4eb4da' },
  { id: 3, label: 'Primavera Clara', background: '#a0ae63' },
  { id: 4, label: 'Outono Suave', background: '#e7876f' },
  { id: 5, label: 'Outono Escuro', background: '#718554' },
  { id: 6, label: 'Outono Quente', background: '#b75d3a' },
  { id: 7, label: 'Inverno Brilhante', background: '#c64182' },
  { id: 8, label: 'Inverno Frio', background: '#3d4983' },
  { id: 9, label: 'Inverno Escuro', background: '#66273a' },
  { id: 10, label: 'Verão Frio', background: '#5f5da6' },
  { id: 11, label: 'Verão Claro', background: '#6a83c5' },
  { id: 12, label: 'Verão Suave', background: '#73a3b7' }
]

export const getContrasts = () => [
  {
    id: 1,
    label: 'ALTO CONTRASTE',
    background: require('../Images/templates/contrast/alto/celebrity.jpg')
  },
  {
    id: 2,
    label: 'MÉDIO CONTRASTE',
    background: require('../Images/templates/contrast/medio/celebrity.jpg')
  },
  {
    id: 5,
    label: 'MÉDIO CONTRASTE PARA ALTO',
    background: require('../Images/templates/contrast/medioalto/celebrity.jpg')
  },
  {
    id: 6,
    label: 'MÉDIO CONTRASTE PARA BAIXO',
    background: require('../Images/templates/contrast/mediobaixo/celebrity.jpg')
  },
  {
    id: 3,
    label: 'BAIXO CONTRASTE CLARO',
    background: require('../Images/templates/contrast/baixoclaro/celebrity.jpg')
  },
  {
    id: 4,
    label: 'BAIXO CONTRASTE ESCURO',
    background: require('../Images/templates/contrast/baixoescuro/celebrity.png')
  }
]

export const getContrastsDownCase = () => [
  {
    id: 1,
    label: 'Alto Contraste'
  },
  {
    id: 2,
    label: 'Médio Contraste'
  },
  {
    id: 5,
    label: 'Médio Contraste para Alto'
  },
  {
    id: 6,
    label: 'Médio Contraste para Baixo'
  },
  {
    id: 3,
    label: 'Baixo Contraste Claro'
  },
  {
    id: 4,
    label: 'Baixo Contraste Escuro'
  }
]

export const getShoppingList = () => [
  {
    title: `Parte Superior`,
    imageBkg: require('../Images/superiorClothes.jpeg'),
    category: 'upperPart',
    categoryLink: 'parte-superior'
  },
  {
    title: `Parte Inferior`,
    imageBkg: require('../Images/inferiorClothes.jpeg'),
    category: 'lowerPart',
    categoryLink: 'parte-inferior'
  },
  {
    title: `Acessórios`,
    imageBkg: require('../Images/accessories.jpeg'),
    category: 'accessories',
    categoryLink: 'acessorios'
  },
  {
    title: `Sapatos`,
    imageBkg: require('../Images/shoes.jpeg'),
    category: 'shoes',
    categoryLink: 'sapatos'
  },
  {
    title: `Outros`,
    imageBkg: require('../Images/other.jpeg'),
    category: 'other',
    categoryLink: 'outros'
  }
]

export const getContentCategories = () => [
  {
    title: `Compartilhe a Mosty nas suas Mídias Sociais`,
    imageBkg: require('../Images/at-image.jpg'),
    categoryLink: 'compartilhe-a-mosty-nas-suas-midias-sociais'
  },
  {
    title: `Como usar melhor a Mosty?`,
    imageBkg: require('../Images/magnifying-glass-doubt.jpg'),
    categoryLink: 'como-usar-melhor-a-mosty'
  },
  {
    title: `Melhorando as finanças`,
    imageBkg: require('../Images/piggy-bank.jpg'),
    categoryLink: 'melhorando-as-financas'
  },
  {
    title: `Como aperfeiçoar o seu negócio?`,
    imageBkg: require('../Images/yellow-lamp.jpg'),
    categoryLink: 'como-aperfeicoar-o-seu-negocio'
  }
]

export const getExpensesList = () => [
  { name: `Despesa Financeira` },
  { name: `Despesa Atendimento` },
  { name: `Despesa Pessoal` },
  { name: `Imposto` },
  { name: `Marketing` },
  { name: `Outras` }
]

export const getMoodboardCategories = () => [
  {
    name: 'Sobre a Cliente',
    rankNumber: 1,
    image: require('../Images/moodboard/about.jpg')
  },
  {
    name: 'Parte superior',
    rankNumber: 2,
    image: require('../Images/moodboard/upperPart.jpg')
  },
  {
    name: 'Parte inferior',
    rankNumber: 3,
    image: require('../Images/moodboard/lowerPart.jpg')
  },
  {
    name: 'Acessórios',
    rankNumber: 4,
    image: require('../Images/moodboard/bag.jpg')
  },
  {
    name: 'Sapatos',
    rankNumber: 5,
    image: require('../Images/moodboard/shoes.jpg')
  },
  {
    name: 'Outros',
    rankNumber: 6,
    image: require('../Images/moodboard/other.jpg')
  }
]

export const getColorTemplate = id => {
  const value = colorMatcher[id]

  const pallete = require(`../Images/templates/coloring/${value[0]}/${value[1]}/paleta.jpeg`)
  const color = require(`../Images/templates/coloring/${value[0]}/${value[1]}/cor.jpeg`)
  const main = require(`../Images/templates/coloring/${value[0]}/principal.jpeg`)

  return [pallete, color, main]
}

export const getContrastTemplate = id => {
  const value = constrastMatcher[id]

  const first = require(`../Images/templates/contrast/${value}/first.jpeg`)
  const second = require(`../Images/templates/contrast/${value}/second.jpeg`)
  const description = require(`../Images/templates/contrast/main/description.jpeg`)

  return [first, second, description]
}

const resolvePlatform = () => {
  if (Platform.OS === 'ios') {
    return 'ios'
  } else if (Platform.OS === 'android') {
    return 'android'
  } else {
    // Web
    if (
      navigator.platform.indexOf('iPhone') !== -1 ||
      navigator.platform.indexOf('iPad') !== -1 ||
      navigator.platform.indexOf('iPod') !== -1
    ) {
      return 'ios'
    } else {
      return 'web'
    }
  }
}

export const openMaps = query => {
  const platform = resolvePlatform()

  const getMapsURL = platform => {
    /* if we're on iOS, open in Apple Maps */
    if (platform === 'ios') {
      return `maps://?daddr=${query}`
    } else if (platform === 'android') {
      return `geo://?daddr=${query}`
    } else {
      return openLinkInNewTab(
        `https://www.google.com/maps/search/?api=1&query=${query}`
      )
    }
  }

  Linking.openURL(getMapsURL(platform))
}

export const openWaze = query => {
  const platform = resolvePlatform()

  const getWazeURL = platform => {
    if (platform === 'android' || platform === 'ios') {
      return `waze://?q=${query}`
    } else {
      return openLinkInNewTab(`https://waze.com/ul?q=${query}`)
    }
  }

  Linking.openURL(getWazeURL(platform))
}

export const openUber = query => {
  const getUberURL = () => {
    return openLinkInNewTab(
      `https://m.uber.com/ul/client_id=oR5_kM9B8Hsxf9BKAXZl7Pm6IcL38n9w&action=setPickup&pickup[latitude]=37.775818&pickup[longitude]=-122.418028&pickup[nickname]=UberHQ&pickup[formatted_address]=1455%20Market%20St%2C%20San%20Francisco%2C%20CA%2094103&dropoff[latitude]=37.802374&dropoff[longitude]=-122.405818&dropoff[nickname]=Coit%20Tower&dropoff[formatted_address]=1%20Telegraph%20Hill%20Blvd%2C%20San%20Francisco%2C%20CA%2094133&product_id=a1111c8c-c720-46c3-8534-2fcdd730040d&link_text=View%20team%20roster&partner_deeplink=partner%3A%2F%2Fteam%2F9383`
    )
  }

  Linking.openURL(getUberURL())
}

export const openWhatsapp = ({ text = '', phone, phoneCountryCode }) => {
  // [TODO] For React-Native, check for a substitute of encodeURIComponent
  const fullPhone = phoneCountryCode ? `${phoneCountryCode}${phone}` : phone
  if (isMobile) {
    return Linking.openURL(
      `https://api.whatsapp.com/send?text=${encodeURIComponent(text)}${
        phone ? `&phone=${fullPhone}` : ''
      }
      `
    )
  }
  // If web, open in new tab
  return openLinkInNewTab(
    `https://web.whatsapp.com/send?text=${encodeURIComponent(text)}${
      phone ? `&phone=${fullPhone}` : ''
    }
      `
  )
}

const openLinkInNewTab = url => {
  if (typeof document === 'undefined') return // Not on a browser!
  const win = window.open(url, '_blank')
  win.focus()
}

export const referralCodeToId = async code => {
  const stringCode = String(code)
  if (typeof stringCode !== 'string') return null
  const { Base64 } = await import('js-base64')
  try {
    const decoded = Base64.decode(stringCode)
    if (isNaN(decoded)) throw new Error('Invalid code')
    const referralId = decoded
      .split('')
      .reverse()
      .join('')
    return parseInt(referralId)
  } catch (err) {
    return null
  }
}

export const referralIdToCode = async id => {
  const idString = String(id)
  if (typeof idString !== 'string') return null
  const { Base64 } = await import('js-base64')
  try {
    if (isNaN(idString)) throw new Error('Invalid ID')
    const encoded = Base64.encode(
      idString
        .split('')
        .reverse()
        .join('')
    )
    return encoded
  } catch (err) {
    return null
  }
}

export const openLink = (url, { target } = { target: '_self' }) => {
  if (Platform.OS === 'web') {
    return window.open(url, target)
  } else {
    return Linking.openURL(url)
  }
}

export const getPremiumColoringCards = () => [
  {
    title: 'Cartela Online',
    image: require('../Images/coloring/sheet.png')
  },
  {
    title: 'Maquiagem',
    image: require('../Images/coloring/makeup.png')
  },
  {
    title: 'Cabelo',
    image: require('../Images/coloring/hair.png')
  },
  {
    title: 'Looks',
    image: require('../Images/coloring/look.png')
  },
  {
    title: 'Acessórios',
    image: require('../Images/coloring/accessories.png')
  },
  {
    title: 'Contraste',
    image: require('../Images/coloring/contrast.png')
  }
]

export const getColorsAlias = () => [
  { id: 1, label: 'Primavera Quente', searchAlias: 'primavera_quente' },
  { id: 2, label: 'Primavera Brilhante', searchAlias: 'primavera_brilhante' },
  { id: 3, label: 'Primavera Clara', searchAlias: 'primavera_clara' },
  { id: 4, label: 'Outono Suave', searchAlias: 'outono_suave' },
  { id: 5, label: 'Outono Escuro', searchAlias: 'outono_escuro' },
  { id: 6, label: 'Outono Quente', searchAlias: 'outono_quente' },
  { id: 7, label: 'Inverno Brilhante', searchAlias: 'inverno_brilhante' },
  { id: 8, label: 'Inverno Frio', searchAlias: 'inverno_frio' },
  { id: 9, label: 'Inverno Escuro', searchAlias: 'inverno_escuro' },
  { id: 10, label: 'Verão Frio', searchAlias: 'verao_frio' },
  { id: 11, label: 'Verão Claro', searchAlias: 'verao_claro' },
  { id: 12, label: 'Verão Suave', searchAlias: 'verao_suave' }
]

export const getProductGroups = () => [
  { label: 'Looks', alias: 'look' },
  { label: 'Maquiagem', alias: 'make_up' },
  { label: 'Acessórios', alias: 'accessory' },
  { label: 'Cabelo', alias: 'hair' }
]

export const getUserMenuSections = () => [
  {
    title: 'Meu Cadastro',
    displayName: 'Meu\nCadastro',
    iconName: 'person',
    dataTest: 'account-data'
  },
  {
    title: 'Meus Serviços',
    displayName: 'Meus\nServiços',
    iconName: 'style',
    link: '/usuario/editar/servicos',
    dataTest: 'services'
  },
  {
    title: 'Controle Financeiro',
    displayName: 'Controle Financeiro',
    iconName: 'trending-up',
    link: '/usuario/financeiro',
    dataTest: 'financial-control'
  },
  // {
  //   title: 'Meus Recebimentos',
  //   displayName: 'Meus Recebimentos',
  //   iconName: 'monetization-on',
  //   link: '/usuario/recebimentos',
  //   dataTest: 'client-charges'
  // },
  {
    title: 'Indique a Mosty',
    displayName: 'Indique a Mosty',
    iconName: 'share',
    link: '/recomendar',
    dataTest: 'share-app'
  },
  {
    title: 'Minhas exportações',
    displayName: 'Minhas Exportações',
    iconName: 'file-download',
    link: '/exportacoes',
    dataTest: 'export'
  }
]

export const sevenStylesTypes = [
  {
    id: 1,
    label: 'Natural',
    reference: 'natural'
  },
  {
    id: 2,
    label: 'Contemporâneo',
    reference: 'contemporary'
  },
  {
    id: 3,
    label: 'Criativo',
    reference: 'creative'
  },
  {
    id: 4,
    label: 'Elegante',
    reference: 'elegant'
  },
  {
    id: 5,
    label: 'Clássico',
    reference: 'classic'
  },
  {
    id: 6,
    label: 'Sexy',
    reference: 'sexy'
  },
  {
    id: 7,
    label: 'Romântico',
    reference: 'romantic'
  }
]

export const tenStylesTypes = [
  {
    id: 1,
    label: 'Romântico',
    reference: 'romantic'
  },
  {
    id: 2,
    label: 'Criativo',
    reference: 'creative'
  },
  {
    id: 3,
    label: 'Natural',
    reference: 'natural'
  },
  {
    id: 4,
    label: 'Clássico',
    reference: 'classic'
  },
  {
    id: 5,
    label: 'Dramático',
    reference: 'dramatic'
  },
  {
    id: 6,
    label: 'Estudante',
    reference: 'student'
  },
  {
    id: 7,
    label: 'Moderno',
    reference: 'modern'
  },
  {
    id: 8,
    label: 'Glamour',
    reference: 'glamour'
  },
  {
    id: 9,
    label: 'Fashion',
    reference: 'fashion'
  },
  {
    id: 10,
    label: 'Sofisticado',
    reference: 'sophisticated'
  }
]

export const masculineSevenStylesTypes = [
  {
    id: 1,
    label: 'Romântico',
    reference: 'masculineRomantic'
  },
  {
    id: 2,
    label: 'Criativo',
    reference: 'masculineCreative'
  },
  {
    id: 3,
    label: 'Sexy',
    reference: 'masculineSexy'
  },
  {
    id: 4,
    label: 'Contemporâneo',
    reference: 'masculineContemporary'
  },
  {
    id: 5,
    label: 'Esportivo',
    reference: 'masculineCasual'
  },
  {
    id: 6,
    label: 'Clássico',
    reference: 'masculineClassic'
  },
  {
    id: 7,
    label: 'Elegante',
    reference: 'masculineElegant'
  }
]

export const allStyles = [
  {
    id: 1,
    label: 'Romântico',
    reference: 'romantic'
  },
  {
    id: 2,
    label: 'Criativo',
    reference: 'creative'
  },
  {
    id: 3,
    label: 'Natural',
    reference: 'natural'
  },
  {
    id: 4,
    label: 'Clássico',
    reference: 'classic'
  },
  {
    id: 5,
    label: 'Dramático',
    reference: 'dramatic'
  },
  {
    id: 6,
    label: 'Estudante',
    reference: 'student'
  },
  {
    id: 7,
    label: 'Moderno',
    reference: 'modern'
  },
  {
    id: 8,
    label: 'Glamour',
    reference: 'glamour'
  },
  {
    id: 9,
    label: 'Fashion',
    reference: 'fashion'
  },
  {
    id: 10,
    label: 'Sofisticado',
    reference: 'sophisticated'
  },
  {
    id: 11,
    label: 'Sexy',
    reference: 'sexy'
  },
  {
    id: 12,
    label: 'Elegante',
    reference: 'elegant'
  },
  {
    id: 13,
    label: 'Contemporâneo',
    reference: 'contemporary'
  },
  {
    id: 14,
    label: 'Romântico',
    reference: 'masculineRomantic'
  },
  {
    id: 15,
    label: 'Criativo',
    reference: 'masculineCreative'
  },
  {
    id: 16,
    label: 'Sexy',
    reference: 'masculineSexy'
  },
  {
    id: 17,
    label: 'Contemporâneo',
    reference: 'masculineContemporary'
  },
  {
    id: 18,
    label: 'Esportivo',
    reference: 'masculineCasual'
  },
  {
    id: 19,
    label: 'Clássico',
    reference: 'masculineClassic'
  },
  {
    id: 20,
    label: 'Elegante',
    reference: 'masculineElegant'
  }
]

export const textToZipCode = text => {
  return `${text.slice(0, 5)}-${text.slice(5, 8)}`
}
