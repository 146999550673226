import { loadable } from '../Services/LoadableComponent'
import { withRouter } from 'react-router'
import {
  requiresAuthentication,
  noAuthentication,
  requiresFeature
} from '../Services/AuthInterface'

const routes = {
  UpsertPaymentMethod: {
    component: requiresAuthentication(
      withRouter(
        loadable(() => import('../Containers/Plans/UpsertPaymentMethod'))
      )
    ),
    path: '/carrinho/metodo-pagamento/novo',
    exact: true
  },
  Cart: {
    component: requiresAuthentication(
      withRouter(loadable(() => import('../Containers/Plans/Cart')))
    ),
    path: '/carrinho',
    exact: true
  },
  PlansList: {
    component: withRouter(
      loadable(() => import('../Containers/Plans/PlansList'))
    ),
    path: '/planos',
    exact: true
  },
  ChallengesList: {
    component: requiresAuthentication(
      withRouter(
        loadable(() => import('../Containers/Challenges/ChallengesList'))
      )
    ),
    path: '/desafios',
    exact: true
  },
  ChallengeConcludedScreen: {
    component: requiresAuthentication(
      withRouter(loadable(() => import('../Containers/Challenges/Concluded')))
    ),
    path: '/desafios/concluido',
    exact: true
  },
  RewardScreen: {
    component: requiresAuthentication(
      withRouter(loadable(() => import('../Containers/Challenges/Reward')))
    ),
    path: '/recompensa',
    exact: true
  },
  ListLevelsScreen: {
    component: requiresAuthentication(
      withRouter(
        loadable(() => import('../Containers/Challenges/ListLevelsScreen'))
      )
    ),
    path: '/niveis',
    exact: true
  },
  RecommendTheApp: {
    component: requiresAuthentication(
      withRouter(loadable(() => import('../Containers/RecommendTheApp')))
    ),
    path: '/recomendar',
    exact: true
  },
  ShoppingListTypeSelection: {
    component: requiresAuthentication(
      requiresFeature(
        withRouter(
          loadable(() =>
            import('../Containers/ShoppingListScreen/ShoppingListTypeSelection')
          )
        ),
        'any'
      )
    ),
    path: '/clientes/:userId/lista-de-compras/tipo',
    exact: true
  },
  ShoppingListNewTemplate: {
    component: requiresAuthentication(
      requiresFeature(
        withRouter(
          loadable(() =>
            import('../Containers/ShoppingListScreen/ShoppingListNewTemplate')
          )
        ),
        'any'
      )
    ),
    path: '/clientes/:userId/lista-de-compras/template/:itemId',
    exact: true
  },
  ShoppingListTemplate: {
    component: requiresAuthentication(
      requiresFeature(
        withRouter(
          loadable(() =>
            import('../Containers/ShoppingListScreen/ShoppingListTemplate')
          )
        ),
        'any'
      )
    ),
    path: '/clientes/:userId/lista-de-compras/template/categoria/:categoria',
    exact: true
  },
  ShoppingListUpsertContainer: {
    component: requiresAuthentication(
      requiresFeature(
        withRouter(
          loadable(() =>
            import(
              '../Containers/ShoppingListScreen/ShoppingListUpsertContainer'
            )
          )
        ),
        'any'
      )
    ),
    path: '/clientes/:userId/lista-de-compras',
    exact: true
  },
  ShoppingListExample: {
    component: requiresAuthentication(
      requiresFeature(
        withRouter(
          loadable(() =>
            import('../Containers/ShoppingListScreen/ShoppingListExample')
          )
        ),
        'any'
      )
    ),
    path: '/clientes/:userId/lista-de-compras/exemplo',
    exact: true
  },
  MoodboardTypeSelection: {
    component: requiresAuthentication(
      requiresFeature(
        withRouter(
          loadable(() =>
            import('../Containers/Moodboard/MoodboardTypeSelection')
          )
        ),
        'completeMoodboard'
      )
    ),
    path: '/clientes/:userId/guia-de-estilo/tipo',
    exact: true
  },
  MoodboardCategoriesTemplate: {
    component: requiresAuthentication(
      requiresFeature(
        withRouter(
          loadable(() =>
            import('../Containers/Moodboard/MoodboardCategoriesTemplate')
          )
        ),
        'completeMoodboard'
      )
    ),
    path: '/clientes/:userId/guia-de-estilo/categorias',
    exact: true
  },
  MoodboardTemplates: {
    component: requiresAuthentication(
      requiresFeature(
        withRouter(
          loadable(() => import('../Containers/Moodboard/MoodboardTemplates'))
        ),
        'completeMoodboard'
      )
    ),
    path: '/clientes/:userId/guia-de-estilo/template/:templateId',
    exact: true
  },
  MoodboardUpsertContainer: {
    component: requiresAuthentication(
      requiresFeature(
        withRouter(
          loadable(() =>
            import('../Containers/Moodboard/MoodboardUpsertContainer')
          )
        ),
        'any'
      )
    ),
    path: '/clientes/:userId/guia-de-estilo/confirmacao',
    exact: true
  },
  MoodboardVisualizeScreen: {
    component: requiresAuthentication(
      requiresFeature(
        withRouter(
          loadable(() =>
            import('../Containers/Moodboard/MoodboardVisualizeScreen')
          )
        ),
        'any'
      )
    ),
    path: '/clientes/:userId/guia-de-estilo',
    exact: true
  },
  MoodboardVisualizeTemplateScreen: {
    component: requiresAuthentication(
      requiresFeature(
        withRouter(
          loadable(() =>
            import('../Containers/Moodboard/MoodboardVisualizeTemplateScreen')
          )
        ),
        'completeMoodboard'
      )
    ),
    path: '/clientes/:userId/guia-de-estilo/visualizacao',
    exact: true
  },
  ColoringTypeSelection: {
    component: requiresAuthentication(
      requiresFeature(
        withRouter(
          loadable(() =>
            import('../Containers/ColoringScreen/ColoringTypeSelection')
          )
        ),
        'coloring'
      )
    ),
    path: '/clientes/:userId/coloracao/tipo',
    exact: true
  },
  ColoringId: {
    component: requiresAuthentication(
      requiresFeature(
        withRouter(
          loadable(() => import('../Containers/ColoringScreen/ColoringId'))
        ),
        'coloring'
      )
    ),
    path: '/clientes/:userId/coloracao/id',
    exact: true
  },
  ContrastId: {
    component: requiresAuthentication(
      requiresFeature(
        withRouter(
          loadable(() => import('../Containers/ColoringScreen/ContrastId'))
        ),
        'coloring'
      )
    ),
    path: '/clientes/:userId/contraste/id',
    exact: true
  },
  ColoringUpsertContainer: {
    component: requiresAuthentication(
      requiresFeature(
        withRouter(
          loadable(() =>
            import('../Containers/ColoringScreen/ColoringUpsertContainer')
          )
        ),
        'coloring'
      )
    ),
    path: '/clientes/:userId/coloracao',
    exact: true
  },
  ColoringClientVisualization: {
    component: requiresAuthentication(
      requiresFeature(
        withRouter(
          loadable(() =>
            import('../Containers/ColoringScreen/ColoringClientVisualization')
          )
        ),
        'coloring'
      )
    ),
    path: '/clientes/:userId/coloracao/visualizacao-cliente',
    exact: true
  },
  TemplateChooseScreen: {
    component: requiresAuthentication(
      requiresFeature(
        withRouter(
          loadable(() =>
            import('../Containers/SilhouetteScreen/TemplateChooseScreen')
          )
        ),
        'any'
      )
    ),
    path: '/clientes/:userId/sobre/escolher',
    exact: true
  },
  SilhouetteTypeSelection: {
    component: requiresAuthentication(
      requiresFeature(
        withRouter(
          loadable(() =>
            import('../Containers/SilhouetteScreen/SilhouetteTypeSelection')
          )
        ),
        'any'
      )
    ),
    path: '/clientes/:userId/sobre/tipo',
    exact: true
  },
  SilhouetteViewScreen: {
    component: requiresAuthentication(
      requiresFeature(
        withRouter(
          loadable(() =>
            import('../Containers/SilhouetteScreen/SilhouetteViewScreen')
          )
        ),
        'any'
      )
    ),
    path: '/clientes/:userId/sobre',
    exact: true
  },
  ClosetTypeSelection: {
    component: requiresAuthentication(
      requiresFeature(
        withRouter(
          loadable(() =>
            import('../Containers/ClosetScreen/ClosetTypeSelection')
          )
        ),
        'virtualCloset'
      )
    ),
    path: '/clientes/:userId/closet/tipo',
    exact: true
  },
  ClosetUpsertContainer: {
    component: requiresAuthentication(
      requiresFeature(
        withRouter(
          loadable(() =>
            import('../Containers/ClosetScreen/ClosetUpsertContainer')
          )
        ),
        'virtualCloset'
      )
    ),
    path: '/clientes/:userId/closet',
    exact: true
  },
  ClosetEditImage: {
    component: requiresAuthentication(
      requiresFeature(
        withRouter(
          loadable(() => import('../Containers/ClosetScreen/ClosetEditImage'))
        ),
        'virtualCloset'
      )
    ),
    path: '/clientes/:userId/closet/:imageId',
    exact: true
  },
  NotesList: {
    component: requiresAuthentication(
      requiresFeature(
        withRouter(loadable(() => import('../Containers/Notes/NotesList'))),
        'any'
      )
    ),
    path: '/clientes/:userId/anotacoes',
    exact: true
  },
  CountryPickerScreen: {
    component: requiresAuthentication(
      requiresFeature(
        withRouter(
          loadable(() =>
            import('../Components/CountryPicker/CountryPickerScreen')
          )
        ),
        'customer'
      )
    ),
    path: '/clientes/:userId/cadastro/selecionar-pais',
    exact: true
  },
  RegisterClientAddressScreen: {
    component: requiresAuthentication(
      requiresFeature(
        withRouter(
          loadable(() =>
            import('../Containers/Auth/RegisterAddressClientScreen')
          )
        ),
        'customer'
      )
    ),
    path: '/clientes/:userId/cadastro/endereco',
    exact: true
  },
  ClientServicesScreen: {
    component: requiresAuthentication(
      requiresFeature(
        withRouter(
          loadable(() => import('../Containers/ClientServicesScreen'))
        ),
        'customer'
      )
    ),
    path: '/clientes/:userId/cadastro/servicos',
    exact: true
  },
  RegisterClientServiceScreen: {
    component: requiresAuthentication(
      requiresFeature(
        withRouter(
          loadable(() =>
            import('../Containers/Auth/RegisterServiceClientScreen')
          )
        ),
        'customer'
      )
    ),
    path: '/clientes/:userId/cadastro/servicos/novo',
    exact: true
  },
  UpsertClientScreen: {
    component: requiresAuthentication(
      requiresFeature(
        withRouter(loadable(() => import('../Containers/UpsertClientScreen'))),
        'customer'
      )
    ),
    path: '/clientes/:userId/cadastro',
    exact: true
  },
  ClientMenuScreen: {
    component: requiresAuthentication(
      requiresFeature(
        withRouter(loadable(() => import('../Containers/ClientMenuScreen'))),
        'customer'
      )
    ),
    path: '/clientes/:userId',
    exact: true
  },
  MyClients: {
    component: requiresAuthentication(
      requiresFeature(
        withRouter(loadable(() => import('../Containers/MyClients'))),
        'customer'
      )
    ),
    path: '/clientes',
    exact: true
  },
  UpsertAgendaEvent: {
    component: requiresAuthentication(
      requiresFeature(
        withRouter(
          loadable(() => import('../Containers/Agenda/UpsertAgendaEvent'))
        ),
        'any'
      )
    ),
    path: '/compromissos/:agendaEventId',
    exact: true
  },
  AgendaList: {
    component: requiresAuthentication(
      requiresFeature(
        withRouter(loadable(() => import('../Containers/Agenda/AgendaList'))),
        'any'
      )
    ),
    path: '/compromissos',
    label: 'Compromissos',
    exact: true
  },
  UpsertUserPasswordScreen: {
    component: noAuthentication(
      withRouter(
        loadable(() => import('../Containers/Auth/UpsertUserPasswordScreen'))
      )
    ),
    path: '/usuario/novo/senha',
    exact: true
  },
  FinancialControlList: {
    component: withRouter(
      loadable(() =>
        import('../Containers/FinancialControl/FinancialControlList')
      )
    ),
    path: '/usuario/financeiro',
    exact: true
  },
  ExpensesList: {
    component: withRouter(
      loadable(() => import('../Containers/FinancialControl/ExpensesList'))
    ),
    path: '/usuario/financeiro/despesas',
    exact: true
  },
  CreateExpense: {
    component: withRouter(
      loadable(() => import('../Containers/FinancialControl/CreateExpense'))
    ),
    path: '/usuario/financeiro/despesas/novo',
    exact: true
  },
  BillingInitial: {
    component: withRouter(
      loadable(() => import('../Containers/Billing/BillingInitial'))
    ),
    path: '/usuario/recebimentos/novo',
    exact: true
  },
  BillingListScreen: {
    component: withRouter(
      loadable(() => import('../Containers/Billing/BillingListScreen'))
    ),
    path: '/usuario/recebimentos',
    exact: true
  },
  BillingUpdateScreen: {
    component: withRouter(
      loadable(() => import('../Containers/Billing/BillingUpdateScreen'))
    ),
    path: '/usuario/recebimentos/:billingId',
    exact: true
  },
  UpsertUserScreen: {
    component: withRouter(
      loadable(() => import('../Containers/Auth/UpsertUserScreen'))
    ),
    path: '/usuario/:action',
    exact: true
  },
  UpsertConsultantServices: {
    component: withRouter(
      loadable(() => import('../Containers/UpsertConsultantServices'))
    ),
    path: '/usuario/editar/servicos/:serviceId',
    exact: true
  },
  ConsultantServices: {
    component: withRouter(
      loadable(() => import('../Containers/ConsultantServicesScreen'))
    ),
    path: '/usuario/editar/servicos',
    exact: true
  },
  ForgotPasswordScreen: {
    component: noAuthentication(
      withRouter(
        loadable(() => import('../Containers/Auth/ForgotPasswordScreen'))
      )
    ),
    path: '/esqueci-a-senha',
    exact: true
  },
  LoginScreen: {
    component: noAuthentication(
      withRouter(loadable(() => import('../Containers/Auth/LoginScreen')))
    ),
    path: '/entrar/email',
    exact: true
  },
  LogRegScreen: {
    component: noAuthentication(
      withRouter(loadable(() => import('../Containers/Auth/LogRegScreen')))
    ),
    path: '/entrar',
    exact: true
  },
  IntroductionScreen: {
    component: requiresAuthentication(
      requiresFeature(
        withRouter(
          loadable(() =>
            import('../Containers/IntroductionScreen/IntroductionScreen')
          )
        ),
        'any'
      )
    ),
    path: '/introducao'
  },
  // VideoScreen: {
  //   component: requiresAuthentication(
  //     withRouter(loadable(() => import('../Containers/Videos/VideoScreen')))
  //   ),
  //   path: '/conteudos/video',
  //   exact: true
  // },
  // ListCategoryScreen: {
  //   component: requiresAuthentication(
  //     withRouter(
  //       loadable(() => import('../Containers/Videos/ListCategoryScreen'))
  //     )
  //   ),
  //   path: '/conteudos',
  //   exact: true
  // },
  // ListVideoScreen: {
  //   component: requiresAuthentication(
  //     withRouter(loadable(() => import('../Containers/Videos/ListVideoScreen')))
  //   ),
  //   path: '/conteudos/:videoCategory',
  //   exact: true
  // },
  HomeScreen: {
    component: requiresAuthentication(
      withRouter(loadable(() => import('../Containers/HomeScreen')))
    ),
    path: '/',
    exact: true
  },
  Exports: {
    component: requiresAuthentication(
      withRouter(loadable(() => import('../Containers/Exports')))
    ),
    path: '/exportacoes',
    exact: true
  }
  // Removed for now "Combinou" feature
  // CombinouAgendaInitalScreen: {
  //   component: requiresAuthentication(
  //     withRouter(
  //       loadable(() =>
  //         import('../Containers/Combinou/CombinouAgendaInitalScreen')
  //       )
  //     )
  //   ),
  //   path: '/combinou',
  //   exact: true
  // },
  // CombinouSetAgenda: {
  //   component: requiresAuthentication(
  //     withRouter(
  //       loadable(() => import('../Containers/Combinou/CombinouSetAgenda'))
  //     )
  //   ),
  //   path: '/combinou/agenda/:action',
  //   exact: true
  // },
  // CombinouSetAgendaDay: {
  //   component: requiresAuthentication(
  //     withRouter(
  //       loadable(() => import('../Containers/Combinou/CombinouSetAgendaDay'))
  //     )
  //   ),
  //   path: '/combinou/agenda/:action/day',
  //   exact: true
  // }
}

export default routes
